<template>
  <Card class="px-3 py-3 flex flex-col w-full gap-3">
    <template>
      <SummaryTable
        :headers="headers"
        :items="data.items"
        :footer="data.footer"
      >
        <template v-slot:title>
          <div class="flex justify-start items-center mb-5">
            <h1 class="font-bold text-lg leading-6 tracking-wide text-flame">
              {{ title }}
            </h1>
          </div>
        </template>
        <template v-slot:item="{ item }">
          <div v-if="item.objectives" class="flex justify-start items-center gap-2">
            <span class="text-darkPurple font-normal text-base leading-5">
              {{ item.data.objectives }}
            </span>
          </div>
          <div v-if="item.criteria" class="text-darkPurple font-normal text-base leading-5">
            <span class="" v-for="(value, i) in item.data.criteria" :key="`${item}-${i}`">
              {{ value }};
            </span>
          </div>
              <div v-if="item.weight" class="flex justify-center items-center gap-2 w-18">
                <span class="text-darkPurple font-semibold text-base leading-5">
                  {{ item.data.weight }}%
                </span>
              </div>
              <div v-if="item.session1" class="flex flex-col justify-center items-center">
                <span class="text-darkPurple font-semibold text-base leading-5">
                  {{ item.data.session1.score }}
                </span>
                <span class="text-romanSilver font-black text-xs leading-6 uppercase">
                  Rating = {{ item.data.session1.rating }}
                </span>
              </div>
        </template>
        <template v-slot:footer="{ footer }">
          <div class="flex justify-between items-center px-5">
              <span class="font-bold text-lg leading-5 text-darkPurple">Total</span>
              <div class="border-b border-dashed border-romanSilver w-8/12" />
              <div class="flex flex-col justify-center items-center py-0 my-0">
                <span class="font-black text-xs leading-6 text-center text-romanSilver uppercase">
                  Final Score
                </span>
                <div>
                  <span class="font-bold text-xl text-center leading-5 text-blueCrayola">
                      {{ footer.score }}
                  </span>
                  <span class="font-semibold text-10px text-darkPurple">
                  </span>
                </div>
                <span class="font-semibold text-10px text-darkPurple uppercase">
                </span>
              </div>
            </div>
        </template>
      </SummaryTable>
    </template>
  </Card>
</template>

<script>
  import Card from "@scelloo/cloudenly-ui/src/components/card";
  import SummaryTable from "@/components/SummaryTable";

  export default {
    name: "PerformanceSummary",
    components: {
      Card,
      SummaryTable
    },
    props: {
      data: {
        type: Object,
        required: true,
        default: (() => {})
      },
      outcome: {
        type: Object,
        required: false,
        default: (() => {})
      },
      title: {
        type: String,
        required: false,
        default: ''
      },
    },
    data() {
      return {
        headers: [
          { title: "Review Objectives", value: "objectives" },
          { title: "Success Criteria (KPI)", value: "criteria" },
          { title: 'Final Performance Score', value: 'session1', width: 17 },
        ],
      };
    },
    methods: {
      percentage(value, total) {
        return (value / total) * 100;
      },
    }
  };
</script>

<style scoped>
  .text-10px {
    font-size: 10px;
  }
</style>
